<template>
  <div>
    <menu-title titleName="服务详情" :isBack="!0"></menu-title>
    <div class="home-page" style="padding: 20px">
      <div class="pd20 bg-w">
        <a-spin :spinning="loading">
          内容内容
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false
    };
  },
  created() {
    //this.loaddata();
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("admin/home", {
          balance_time_type: this.search.balance_time_type,
          integral_time_type: this.search.integral_time_type,
        })
        .then((res) => {
          this.shop = res.shop;
          this.kefu = res.kefu;
          this.balance_recharge_data = res.balance_recharge_data;
          this.balance_consume_data = res.balance_consume_data;
          this.coupon_consume_data = res.coupon_consume_data;
          this.balance_rank_data = res.balance_rank_data;
          this.integral_rank_data = res.integral_rank_data;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
   
  },
};
</script>

<style>
.home-page {
  min-width: 1000px;
}
</style>
